export default [
  {
    title: 'menu-getting-started',
    icon: 'rocket',
    href: '/getting-started',
  },
  {
    title: 'menu-about',
    icon: 'chatFilled',
    href: '/about',
  },
  {
    title: 'menu-design-guidelines',
    icon: 'penTool',
    href: '/design-guidelines',
    children: [
      { href: '/design-guidelines/accessibility', title: 'menu-accessibility' },
      { href: '/design-guidelines/color', title: 'menu-color' },
      { href: '/design-guidelines/data-visualization/models', title: 'menu-data-visualization' },
      { href: '/design-guidelines/grid', title: 'menu-grid' },
      { href: '/design-guidelines/iconography', title: 'menu-iconography' },
      { href: '/design-guidelines/typography', title: 'menu-typography' },
    ],
  },
  {
    title: 'menu-usage',
    icon: 'code',
    href: '/usage',
    children: [
      { href: '/usage/styles', title: 'menu-styles' },
      { href: '/usage/theme', title: 'menu-theme' },
      { href: '/usage/i18n', title: 'menu-internationalization' },
    ],
  },
  {
    title: 'menu-components',
    icon: 'bricksFilled',
    href: '/components',
    children: [
      { href: '/components/overview', title: 'menu-overview' },
      { href: '/components/accordion', title: 'menu-accordion' },
      { href: '/components/alert', title: 'menu-alert' },
      { href: '/components/breadcrumb', title: 'menu-breadcrumb' },
      { href: '/components/button', title: 'menu-button' },
      { href: '/components/cards', title: 'menu-cards' },
      { href: '/components/checkbox', title: 'menu-checkbox' },
      { href: '/components/content-switch', title: 'menu-content-switch' },
      { href: '/components/date-picker', title: 'menu-date-picker' },
      { href: '/components/dropdown', title: 'menu-dropdown' },
      { href: '/components/file-uploader', title: 'menu-file-uploader' },
      { href: '/components/form', title: 'menu-form' },
      { href: '/components/link', title: 'menu-link' },
      { href: '/components/modal', title: 'menu-modal' },
      { href: '/components/month-paginator', title: 'menu-month-paginator' },
      { href: '/components/multi-select', title: 'menu-multi-select' },
      { href: '/components/paginator', title: 'menu-paginator' },
      { href: '/components/radio-button', title: 'menu-radio-button' },
      { href: '/components/search', title: 'menu-search' },
      { href: '/components/select', title: 'menu-select' },
      { href: '/components/stepper', title: 'menu-stepper' },
      { href: '/components/switch', title: 'menu-switch' },
      { href: '/components/table', title: 'menu-table' },
      { href: '/components/tabs', title: 'menu-tabs' },
      { href: '/components/tag', title: 'menu-tag' },
      { href: '/components/text-field', title: 'menu-text-field' },
      { href: '/components/toast', title: 'menu-toast' },
      { href: '/components/tooltip', title: 'menu-tooltip' },
    ],
  },
  {
    title: 'menu-resources',
    icon: 'archiveFilled',
    href: '/resources',
  },
  {
    title: 'menu-storybook',
    icon: 'list',
    target: '_blank',
    href: 'https://bold.bridge.ufsc.br/storybook',
  },
]
